import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MainViewComponent } from './main-view/view/main-view/main-view.component';
import { HashPanComponent } from './hash-pan/hash-pan.component';
import { RouterModule } from '@angular/router';
import { AboutServiceComponent } from './about-service/view/about-service/about-service.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { IvNgLocalizationModule } from './localization/iv-ng-localization.module';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { MessageService } from 'primeng/api';
import { CookieService } from 'ngx-cookie-service';
import { LoginComponent } from './login/view/login/login.component';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';
import { TextMaskModule } from 'angular2-text-mask';
import { RegistrationComponent } from './registration/view/registration/registration.component';
import { PasswordRecoveryComponent } from './password-recovery/view/password-recovery/password-recovery.component';
import { AboutMugComponent } from './about-mug/view/about-mug/about-mug.component';
import { metaReducers, reducers } from './app-state/reducers/reducers';
import { StoreModule } from '@ngrx/store';
import { AuthDirective } from './security/directive/auth.directive';
import { AuthService } from './security/service/auth.service';
import { httpInterceptorProviders } from './http/http-interceptors';
import { HttpErrorHandler } from './http/http-error-handler.service';
import { MessagesModule } from 'primeng/messages';
import { CarouselModule } from 'primeng/carousel';
import { ConfigLoader, ConfigModule } from '@ngx-config/core';
import { ConfigMergeLoader } from '@ngx-config/merge-loader';
import { ConfigHttpLoader } from '@ngx-config/http-loader';
import { EnvironmentProviderService } from './helpers/environment-provider.service';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ExternalUrlDirective } from './helpers/directive/external-url.directive';
import { NotFoundComponent } from './helpers/directive/not-found/not-found.component';
import { ConfirmDeleteUserDialogComponent } from './confirm-delete-user-dialog/view/confirm-delete-user-dialog/confirm-delete-user-dialog.component';
import { IvCommonModule } from './iv-common/iv-common.module';
import { ConsentComponent } from './consent/consent.component';
import { RecaptchaModule, RECAPTCHA_LANGUAGE } from 'ng-recaptcha';

export function configFactory(http: HttpClient): ConfigLoader {
    const settingsConfig = './assets/settings.json';
    const buildInfo = './assets/build-info.json';

    return new ConfigMergeLoader([new ConfigHttpLoader(http, settingsConfig), new ConfigHttpLoader(http, buildInfo)]);
}

@NgModule({
    declarations: [
        AppComponent,
        MainViewComponent,
        HashPanComponent,
        AboutServiceComponent,
        LoginComponent,
        RegistrationComponent,
        PasswordRecoveryComponent,
        AboutMugComponent,
        AuthDirective,
        ExternalUrlDirective,
        NotFoundComponent,
        ConfirmDeleteUserDialogComponent,
        ConsentComponent,
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        RouterModule,
        IvNgLocalizationModule.forRoot(['ru', 'en']),
        StoreModule.forRoot(reducers, { metaReducers }),
        ReactiveFormsModule,
        NgxMaskModule.forRoot(),
        TextMaskModule,
        MessagesModule,
        CarouselModule,
        ConfigModule.forRoot({
            provide: ConfigLoader,
            useFactory: configFactory,
            deps: [HttpClient],
        }),
        BsDatepickerModule.forRoot(),
        IvCommonModule,
        RecaptchaModule,
    ],
    providers: [
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        MessageService,
        CookieService,
        HttpErrorHandler,
        httpInterceptorProviders,
        AuthService,
        EnvironmentProviderService,
        {
            provide: RECAPTCHA_LANGUAGE,
            useValue: 'ru'
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
