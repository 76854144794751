import { AppState } from '../states/app.states';
import { ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';
import { environment } from '../../../environments/environment.prod';
import { sessionTokenReducer } from './sessiontoken.reducer';

export const reducers: ActionReducerMap<AppState> = {
    sessionTokenState: sessionTokenReducer,
};

export function logger(reducer: ActionReducer<AppState>): ActionReducer<AppState> {
    // tslint:disable-next-line:only-arrow-functions
    return function(state: AppState, action: any): AppState {
        console.log('state', state);
        console.log('action', action);
        return reducer(state, action);
    };
}

export const metaReducers: MetaReducer<AppState>[] = !environment.production ? [logger] : [];
