import { InjectionToken, NgModule } from '@angular/core';
import { Routes, RouterModule, ActivatedRouteSnapshot } from '@angular/router';
import { MainViewComponent } from './main-view/view/main-view/main-view.component';
import { HashPanComponent } from './hash-pan/hash-pan.component';
import { HistoryGuard } from './security/guards/history.guard';
import { NotFoundComponent } from './helpers/directive/not-found/not-found.component';

const externalUrlProvider = new InjectionToken('externalUrlRedirectResolver');

const routes: Routes = [
    {
        path: 'externalRedirect',
        canActivate: [externalUrlProvider],
        component: NotFoundComponent,
    },
    { path: '', redirectTo: '/main-view', pathMatch: 'full' },
    { path: 'main-view', component: MainViewComponent },
    {
        path: 'ride-history',
        canActivate: [HistoryGuard],
        loadChildren: () => import('./ride-history/ride-history.module').then((m) => m.RideHistoryModule),
    },
    // TODO: узнать что это
    {
        path: 'hash-pan',
        canActivate: [HistoryGuard],
        component: HashPanComponent,
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
    providers: [
        {
            provide: externalUrlProvider,
            useValue: (route: ActivatedRouteSnapshot) => {
                const externalUrl = route.paramMap.get('externalUrl');
                window.open(externalUrl, '_self');
            },
        },
    ],
})
export class AppRoutingModule {}
