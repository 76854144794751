<app-confirm-delete-user-dialog
    (onOk)="deleteUserProfile()"
    (onCancel)="hideConfirmToDeleteUserProfileDialog()"
></app-confirm-delete-user-dialog>
<app-message-dialog
    [id]="'messageDialog'"
    [messageDialogTitle]="messageDialogTitle"
    [infoMessage]="infoMessage"
    (onOk)="onOk()"
></app-message-dialog>
<app-consent (delete)="deleteUserProfile()"></app-consent>
<div class="modal fade" id="mobileMenuModal" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-slideout mobile-menu modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <div class="modal-title pull-left">
                    <a class="navbar-brand" href="#">
                        <img src="{{ currentLogoBlackURL }}" alt="Brand logo" />
                    </a>
                </div>
                <button type="button" class="close img-button" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body">
                <ul class="nav flex-column bg-white">
<!--                    <li class="nav-item">-->
<!--                        <a-->
<!--                            class="nav-link"-->
<!--                            data-dismiss="modal"-->
<!--                            routerLinkActive="active"-->
<!--                            routerLink="{{ currentRout }}"-->
<!--                            [routerLinkActiveOptions]="{ exact: true }"-->
<!--                            (click)="changeCurrentLanguage()"-->
<!--                            >{{ 'app.switchToLang' | translate }}</a-->
<!--                        >-->
<!--                    </li>-->
                    <li appAuth [hideIfNotAuthenticated]="true" class="nav-item">
                        <a
                            class="nav-link"
                            routerLinkActive="active"
                            routerLink="{{ currentRout }}"
                            [routerLinkActiveOptions]="{ exact: true }"
                            (click)="logout()"
                            >{{ 'main-menu.logout' | translate }}</a
                        >
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
<app-about-service></app-about-service>

<div class="d-flex flex-column vh-100">
    <nav class="navbar navbar-dark bg-dark ml-md-1" id="nav-bar" style="height: 85px;">
        <a class="navbar-brand" href="#">
            <img src="{{ currentLogoWhiteURL }}" class="{{ brandLogoCSSClass }}" alt="Brand logo" />
        </a>
        <div class="d-block d-xl-none nav justify-content-end">
            <button type="button" class="img-button" data-toggle="modal" data-target="#mobileMenuModal">
                <img src="assets/layout/images/mobile-menu-button.svg" width="28" height="20" />
            </button>
        </div>
        <div class="d-none d-xl-block main-menu">
            <ul class="nav justify-content-end">
                <!-- TODO: убрать, когда бек заработает -->
                <li class="nav-item">
                    <a
                        class="nav-link"
                        routerLinkActive="active"
                        routerLink="{{ currentRout }}"
                        [routerLinkActiveOptions]="{ exact: true }"
                        (click)="changeCurrentLanguage()"
                        >{{ 'app.switchToLang' | translate }}</a
                    >
                </li>
                <li appAuth [hideIfNotAuthenticated]="true" class="nav-item">
                    <a
                        class="nav-link"
                        routerLinkActive="active"
                        routerLink="{{ currentRout }}"
                        [routerLinkActiveOptions]="{ exact: true }"
                        (click)="logout()"
                        >{{ 'main-menu.logout' | translate }}</a
                    >
                </li>
            </ul>
        </div>
    </nav>
    <div class="flex-grow-1">
        <router-outlet> </router-outlet>
    </div>
</div>
